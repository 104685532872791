.about{
    &_img{
        float: right;
        width: 400px;
        margin-left: 20px;
        margin-bottom: 20px;
        box-shadow: 0 0 10px #000;
        @media (max-width: 1100px){
            width: 100%;
        }
    }
    &_heading{
        text-align: center;
        color: #0069af;
        margin-top: 0;
        font-size: 30px;
        @media (max-width: 600px){
            font-size: 25px;
        }
    }
    &_text{
        font-family: $secondaryFont;

    }
}
