html{
  height: 100%;
  box-sizing: border-box;
  font-family: $primaryFont;

}

*, *:before, *:after{
  box-sizing: inherit
}

body{
  position: relative;
  min-height: 100%;
  min-width: 320px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: visible !important;
}

img{
	display: block;
	max-width: 100%;
	height: auto;
}

.container{
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.wrapper{
  display: flex;
  flex-direction: column;
}

.img-cover,
.img-contain{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-cover{
  object-fit: cover;
}

.img-contain{
  object-fit: contain;
}

.img-wrap{
  position: relative;
}

.img-wrap:before{
  content: '';
  display: block;
  padding-bottom: 60%;
}

// input, textarea{
//   border: #666 1px solid;
//   &:focus:required:invalid{
//     border-color: red;
//   }
//   &:required:valid{
//     border-color: green;
//   }
// }

.link{
  text-decoration: none;
  &:focus{
   outline: none
  }
}

.visually-hidden{
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}

.no-scroll{
  overflow: hidden !important;
}
.mobile-menu{
  transition: all .3s ease;
  transform: translateX(0%) !important;
}

.clear{
    clear: both;
}

