.error{
    background: url("../img/bg/bg-error.jpg") no-repeat;
    background-size: cover;
    text-align: center;
    padding-top: 40px;
    height: 100vh;
    &_heading{
        font-size: 38px;
        font-weight: 700;
        padding-bottom: 20px;
        margin-bottom: 40px;
        border-bottom: 3px solid #6da0da;
    }
    &_title{
        font-size: 20px;
    }
    &_text{
        margin-bottom: 40px;
    }
    &_link{
        display: inline-block;
        color: #1874c1;
        border: 1px solid #1874c1;
        padding: 10px 10px;
        border-radius: 5px;
        font-size: 25px;
        font-weight: 700;
        transition: all .3s ease;
    }
    &_link:hover{
        background: #1874c1;
        color: #fff;
    }
}