.price{
    &_table{
        width: 100%;
        margin: 0 auto;
        border: 1px solid #000;
        border-collapse: collapse;
        margin-bottom: 30px;
        @media (max-width: 600px){
            font-size: 14px;
        }
    }
    &_table caption{
        font-size: 28px;
        font-weight: 500;
        padding-bottom: 6px;
        color: #0069af;
        @media (max-width: 600px){
            font-size: 20px;
        }
    }
    &_table th{
        font-family: $secondaryFont;
        border: 1px solid #000;
        background: #87c2d3;
        padding: 4px 0;
    }
    &_table td{
        border: 1px solid #000;
        width: 50%;
        padding: 4px 7px;
    }
    &_table td:nth-child(odd){
    }
    &_table td:nth-child(even){
          text-align: center;
      }
}
