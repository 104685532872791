.p-service{
    &_main-title{
        font-family: $secondaryFont;
        text-align: center;
        color: #0069af;
        font-size: 25px;

    }
    &_title{
        font-family: $secondaryFont;
        text-align: left;
        //color: #6da0da;
        font-size: 19px;

    }
    &_text{
        font-family: $secondaryFont;
        margin-top: 4px;
    }
    &_text_styled{
        font-family: $secondaryFont;
    }
    &_menu-list{
        font-family: $secondaryFont;
        color: #7d98ad;
        margin: 0 10px 10px;
        list-style: none;
        @media (max-width: 500px){
            padding-left: 10px;
        }
    }
    &_menu-item{
        position: relative;
        margin-top: 5px;
    }
    &_menu-item:after{
        content: '';
        position: absolute;
        left: -20px;
        top: 3.5px;
        width: 12px;
        height: 12px;
        border: 3px solid #1893D9;
        border-radius: 50%;
        background-color: #fff;
    }
    &_menu-item_color{
        color: #000 !important;
    }
    &_text-marked{
        padding: 20px 30px;
        background: #f3f8fa;
        border-left: 5px solid #86d0e4;
        font-family: $secondaryFont;
    }
    &_info{
        display: flex;
        background: #dff2fe;
        border: 2px solid #cfebfe;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    &_info-img{
        display: flex;
        width: 100px;
        @media (max-width: 500px){
            display: none;
        }
    }
    &_info-img .icon{
        margin: auto;
        width: 30px;
        height: 30px;
    }
    &_info-text-wrapper{
        font-family: $secondaryFont;
        font-weight: 500;
        flex: 1;
        padding: 20px 20px 20px 0;
        @media (max-width: 500px){
            padding: 20px;
        }

    }
    &_info-text{
        display: inline-block;

    }

    &_table{
        font-family: $secondaryFont;
        width: 100%;
        margin: 0 auto;
        border: 1px solid #000;
        border-collapse: collapse;
        margin-bottom: 30px;
        margin-top: 30px;
        @media (max-width: 600px){
            font-size: 14px;
        }
    }
    &_table caption{
        font-size: 24px;
        font-weight: 500;
        padding-bottom: 6px;
        @media (max-width: 600px){
            font-size: 20px;
        }
    }
    &_table th{
        border: 1px solid #000;
        background: #52a0b7;
        padding: 4px 0;
        font-size: 18px;
    }
    &_table td{
        border: 1px solid #000;
        padding: 4px 7px;
        width: 50%;
        font-size: 14px;
    }
    &_table td:nth-child(even){
        text-align: center;
    }
    &_img_left{
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        @media (max-width: 700px){
            float: none;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &_img_right{
        float: right;
        margin-left: 20px;
        margin-bottom: 20px;
        @media (max-width: 700px){
            float: none;
            margin-left: auto;
            margin-right: auto;
        }
    }
}


