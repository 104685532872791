.contacts{
    &_info-item:not(:last-child){
        border-bottom: 2px dashed #6DA0DA;
    }
    &_info-title{
        color: #0069af;
        margin-bottom: 10px;
    }
    &_info-text{
        margin-top: 0;
        margin-bottom: 20px;
    }
}
