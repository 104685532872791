.gallery-full{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
}
.gallery-full a{
    width: 50%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    @media (max-width: 700px){
        width: 100%;
}
}
