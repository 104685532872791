


.main-header{
    display: flex;
    flex-direction: column;
    background: #fff;
    &_top-line{
        display: flex;
        padding-top: 10px;
        @media (max-width: 800px){
            flex-direction: column;
        }
    }
    &_logo{
        width: 300px;
        @media (max-width: 800px){
            margin: 0 auto;
        }
    }
    &_content{
        display: flex;
        flex-wrap: wrap;
        font-family: $secondaryFont;
        flex: 1;
        padding-left: 5%;
        font-size: 18px;
        font-weight: 400;
        @media (max-width: 1150px){
            padding-left: 2%;
        }
        .icon {
            width: 25px;
            height: 25px;
            margin-top: auto;
            margin-bottom: auto;
            fill: #6DA0DA;
        }

    }

    &_contacts-list{
        display: flex;
        width: 100%;
        @media (max-width: 485px){
            flex-direction: column;
        }
    }
    &_contacts-item{
        display: flex;
        flex: 1;
        @media (max-width: 485px){
            margin-top: 10px;
            margin-left: 30px;
        }

    }
    &_contacts-item:last-child{
        @media (max-width: 1070px){
            display: none;
        }
    }
    &_address{
        position: relative;
        display: inline-block;
        padding-left: 15px;
        margin: auto auto auto 15px;

        &:before {
            position: absolute;
            display: inline-block;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto 0;
            min-height: 2.5em;
            width: 1px;
            background: #87c2d3;
        }
    }
    &_phone {
        position: relative;
        display: inline-block;
        font-weight: 600;
        font-size: 18px;
        padding-left: 15px;
        margin: auto auto auto 15px;
        &:before {
            position: absolute;
            display: inline-block;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto 0;
            min-height: 2.5em;
            width: 1px;
            background: #87c2d3;
        }
    }
    &_phone_marked{
        color: #0069af;
    }
    &_mail{
        position: relative;
        display: block;
        padding-left: 15px;
        margin: auto auto auto 15px;
        &:before {
            position: absolute;
            display: inline-block;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto 0;
            min-height: 2.5em;
            width: 1px;
            background: #87c2d3;
        }
    }
    &_schedule{
        width: 100%;
        padding: 10px 0;

    }
    &_schedule-text{
        padding-top: 15px;
        border-top: 1px solid #87c2d3;
    }
    &_schedule-text_marked{
        font-weight: 500;
    }
    & .hamburger{
        &:focus{
            outline: none;
        }
        @media (max-width: 700px){
            display: inline-block;
            float: right;
        }
    }
    &_menu{
        background: #009ce4;


    }
    &_menu-list{
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        @media (max-width: 700px){
            position: fixed;
            width: 70%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 300;
            background: rgba(#000, 0.7);
            flex-direction: column;
            text-align: center;
            transform: translateX(-100%);
        }
    }
    &_menu-item{
        position: relative;
        color: #fff;
        font-size: 18px;
        list-style: none;
        transition: all .3s ease;
        cursor: pointer;
        font-weight: 500;
        @media (max-width: 700px){
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            padding: 0 !important;
        }
        &:hover{
            background: #0079b1;
            color: #fff;
        }
    }
    &_menu-services:hover &_menu-services-sublist{
        display: block;
        @media (max-width: 700px) {
            display: none;
        }
    }
    &_menu-services-sublist{
        display: none;
        position: absolute;
        top: 100%;
        width: 210%;
        left: 0;
        z-index: 100;
        background: #009ce4;
        font-size: 15px;
        list-style: none;
        padding: 0;
        border-top: 1px solid #0460c8;
    }
    &_menu-services-subitem{
        padding: 5px 10px;
        transition: all .3s ease;
        &:hover{
            background: #0079b1;
        }
    }
    &_menu-item-link{
        display: inline-block;
        text-decoration: none;
        padding: 16px 30px;
        color: inherit;
        @media (max-width: 900px){
            padding: 16px 15px;
        }
    }
}

//slider
    .owl-carousel{
        background: url("../img/bg/bg-cabinet.jpg") no-repeat center;
        background-size: cover;
        min-height: 250px;
        & .owl-item{
        }
        &_slide{
            display: flex;
        }
        &_content{
            font-family: $secondaryFont;
            width: 60%;
            color: #fff;
            padding: 8% 5% 12%;
            @media (max-width:1100px) {
                width: 50%;
            }
            @media (max-width:900px) {
                width: 60%;
            }
            @media (max-width:730px) {
                width: 70%;
            }
            @media (max-width:630px) {
                width: 100%;
            }
        }
        &_title{
            font-weight: 400;
            font-size: 30px;
            text-shadow: 7px 7px 7px #000;
            line-height: 1;
            @media (max-width:900px) {
                font-size: 28px;
            }
        }
        &_text{
            font-weight: 300;
            font-size: 20px;
            margin-top: 20px;
            text-shadow: 5px 5px 5px #000;
            @media (max-width:900px) {
                font-size: 18px;
            }
        }
        &_img{
            position: relative;
            width: 40%;
            @media (max-width:900px) {
                display: none;
            }
        }
        & .owl-carousel_slide1 img{
            border-radius: 100%;
        }
        &_slide1-img1{
            position: absolute;
            width: 120px;
            height: 120px;
            z-index: 100;
            top: 45px;
            left: 0px;
        }
        &_slide1-img2{
            position: absolute;
            width: 100px;
            height: 100px;
            top: 30px;
            left: 200px;
        }
        &_slide1-img3{
            position: absolute;
            width: 200px;
            height: 200px;
            bottom: 20px;
            left: 20px;
        }
        &_slide1-img4{
            position: absolute;
            width: 150px;
            height: 150px;
            z-index: 100;
            bottom: 30px;
            left: 190px;
        }

        &_slide2-img1{
            position: absolute;
            top: 0;
            right: 0;
            width: 350px !important;
            height: 350px;
        }

        &_slide3-img1{
            position: absolute;
            bottom: 0;
        }

        & .owl-dots{
            position: absolute;
            display: block;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            @media (max-width: 400px){
                display: none;
            }
        }
        & .owl-dot{
            width: 55px;
            height: 10px;
            margin-right: 10px;
            border-radius: 5px;
            background: #889cff !important;
            &.active{
                background: #ffb13f !important;
            }
        }

    }

//WELCOME
.welcome{
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    &_inner{
    }
    &_content{
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        @media (max-width: 750px){
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    &_img{
        display: block;
        float: left;
        max-width: 600px;
        margin-right: 80px;
        margin-bottom: 30px;
        @media (max-width: 1060px){
            margin-right: 50px;
        }
        @media (max-width: 950px){
            max-width: 300px;
            margin-right: 30px;
            margin-bottom: 15px;
        }
        @media (max-width: 750px){
            float: none;
            margin-left: auto !important;
            margin-right: auto !important;
            text-align: center;
            max-width: 100% !important;
        }
    }
    &_heading{
        font-size: 30px;
        font-weight: 500;
        margin: 0;
        color: #0069af;
        &:after{
            border-bottom: 2px solid #ccc;
            content: "";
            display: block;
            margin: 18px auto;
            max-width: 200px;
        }
        @media (max-width: 800px) {
            font-size: 30px;
        }
        @media (max-width: 450px) {
            font-size: 25px;
        }
    }
    &_text{
        font-family: $secondaryFont;
        font-size: 18px;
        margin: 5px 0;
        letter-spacing: 2px;
        text-align: left;
        text-indent: 20px;
        margin-top: 15px;
        @media (max-width: 500px) {
            font-size: 15px;
        }
    }
    &_btn{
        display: inline-block;
        font-family: $advancedFont;
        transition: all .3s ease;
        color: #0069af;
        padding: 7px 25px;
        border: 2px solid #0069af;
        //text-transform: uppercase;
        border-radius: 20px;
        font-size: 20px;
        font-weight: 500;
        margin-top: 15px;
        &:hover{
            background: #0069af;
            color: #fff;
        }
    }

}

//CHOICE START
.choice{
    background-size: cover;
    &_inner{
        display: flex;
        color: #fff;
        padding-top: 40px;
        padding-bottom: 20px;
        @media (max-width:720px) {
            flex-direction: column;
            padding-top: 20px;
            padding-bottom: 0;
            padding-left: 20px;
        }
    }
    &_heading{
        font-size: 35px;
        font-weight: 400 !important;
        //text-shadow: 5px 5px 5px #000;
        padding-top: 70px;
        padding-right: 10px;
        width: 25%;
        margin: 0;

        @media (max-width:870px) {
            padding-top: 0;
            width: 30%;
        }
        @media (max-width: 800px) {
            font-size: 30px;
        }
        @media (max-width: 450px) {
            font-size: 25px;
        }
        @media (max-width:720px) {
            width: 100% !important;
            text-align: center;
            margin-bottom: 20px;
        }

    }
    &_items{
        font-family: $secondaryFont;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 75%;
        @media (max-width:720px) {
            width: 100%;

        }
    }
    &_item{
        width: 45%;
        margin-bottom: 20px;
        @media (max-width:870px) {
            width: 100%;
        }
    }
    &_title{
        font-size: 20px;
    }
    &_text{
        margin-bottom: 0;
    }
    & .icon{
        width: 30px;
        height: 28px;
    }
}

//ADVANTAGES

.advantages{
    color: #fff;
    &_inner{
    background-size: cover;
    padding-top: 40px;
    padding-bottom: 20px;
    }
    &_title{
        position: relative;
        font-family: $primaryFont;
        text-align: center;
        font-size: 30px;
        margin: 0;
        font-weight: 500;
        @media (max-width: 800px) {
            font-size: 30px;
        }
        @media (max-width: 450px) {
            font-size: 25px;
        }
        &:after{
            border-bottom: 2px solid #ccc;
            content: "";
            display: block;
            margin: 18px auto;
            max-width: 150px;
        }
    }
    &_list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    &_item{
        width: 33%;
        text-align: center;
        margin-bottom: 20px;
        @media (max-width: 850px){
            width: 45%;
        }
        @media (max-width: 550px){
            width: 100% !important;
        }
    }
    &_item:nth-child(4){
        margin-left: 15%;
        @media (max-width: 850px){
            margin-left: auto;
        }
    }
    &_item:nth-child(5){
        margin-right: 15%;
        @media (max-width: 850px){
            margin-left: auto;
            margin-right: auto;
        }
    }
    &_item-img{
        margin-bottom: 10px;
    }
    &_item-img .icon{
        width: 50px;
        height: 50px;
        fill: #fff;
    }
    &_item-title{
        font-family: $primaryFont;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 10px;
        line-height: 1;
    }
    &_item-text{
        font-family: $secondaryFont;
        font-size: 16px;
        line-height: 1.2;
    }
}

//SERVICES

.services{
    &_wrapper{
        padding-top: 40px;
        padding-bottom: 20px;
    }
    &_heading{
        position: relative;
        font-size: 30px;
        text-align: center;
        font-weight: 500;
        color: #0069af;
        margin: 0;
        &:after{
            border-bottom: 2px solid #ccc;
            content: "";
            display: block;
            margin: 18px auto;
            max-width: 150px;
        }
        @media (max-width: 800px) {
            font-size: 30px;
        }
        @media (max-width: 450px) {
            font-size: 25px;
        }
    }
    &_list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 30px;
    }
    &_item{
        position: relative;
        width: 31%;
        border-radius: 10px;
        overflow: hidden;
        transition: all .3s ease;
        margin-bottom: 3%;
        @media (max-width:1000px) {
            width: 48%;
            align-items: center;
            justify-content: center;
        }
        @media (max-width:550px) {
            width: 100% !important;
        }
        &:last-child{
            @media (max-width:1000px) {
                margin-left: auto;
                margin-right: auto;
            }

        }
        &:before{
            content: '';
            display: block;
            padding-bottom: 60%;
        }

        &:hover{
            //box-shadow: 0px 0px 22px 3px #6388cc;

        }
        &:hover .services_link{
            text-decoration: underline;

        }
        &:hover .services_img{
            transform: scale(1.05, 1.05);
        }
    }


    &_img{
        transition: all .3s ease;
    }
    &_overlay{
        position: absolute;
        font-family: $secondaryFont;

        font-weight: 500;
        color: #fff;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0,0,0, .2);
        display: flex;
        justify-content: center;

    }
    &_link{
        position: absolute;
        text-align: center;
        bottom: 20px;
        font-size: 22px;

    }

}


//GALLERY
.licences{
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: hidden;
    &_title{
        position: relative;
        font-size: 30px;
        text-align: center;
        font-weight: 500;
        color: #0069af;
        margin: 0;
        @media (max-width: 800px) {
            font-size: 30px;
        }
        @media (max-width: 450px) {
            font-size: 25px;
        }
        &:after{
            border-bottom: 2px solid #ccc;
            content: "";
            display: block;
            margin: 18px auto;
            max-width: 150px;
        }
    }
    & .gallery{
        display: flex;
        justify-content: space-between;
        padding: 0px 60px 10px;
        @media (max-width: 750px){
            flex-wrap: wrap;
        }
        &_item{
            @media (max-width: 750px){
            flex-wrap: wrap;
            width: 50%;
            margin-top: 10px;
         }
        }
        & img {
            padding: 5px;
            border: 1px solid #3372b3;
            transition: all .3s ease;
        }
        & img:hover{
            //box-shadow: 0px 0px 12px -1px #3372b3;
            box-shadow: 0px 0px 6px -1px #000;
        }
    }
    &_link-wrapper{
        text-align: center;
        margin-top: 20px;
    }
    &_link{
        display: inline-block;
        font-family: $secondaryFont;
        transition: all .3s ease;
        color: #0069af;
        padding: 7px 25px;
        border: 2px solid #0069af;
        border-radius: 20px;
        font-size: 20px;
        font-weight: 500;
        &:hover{
            background: #0069af;
            color: #fff;
        }
    }


}



//PHOTO PARALLAX START

.parallax-window {
    background: transparent;
    overflow: hidden;
    &_wrapper{
        padding-top: 40px;
        padding-bottom: 40px;
        @media (max-width: 1000px){
            padding-top: 10%;
            padding-bottom: 10%;
        }
    }
    &_img{
        display: block;
        width: 100px;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
    }
    &_text{
        font-family: $secondaryFont;
        font-weight: 400;
        margin: 0;
        color: #fff;
        font-size: 30px;
        text-align: center;
        margin-top: 20px;
        @media (max-width: 450px) {
            font-size: 25px;
        }
    }
}

//MAP

.map{
    padding-top: 40px;
    padding-bottom: 40px;
    &_heading{
        position: relative;
        font-size: 30px;
        text-align: center;
        font-weight: 500;
        color: #0069af;
        margin: 0;
        @media (max-width: 800px) {
            font-size: 30px;
        }
        @media (max-width: 450px) {
            font-size: 25px;
        }
        &:after{
            border-bottom: 2px solid #ccc;
            content: "";
            display: block;
            margin: 18px auto;
            max-width: 150px;
        }
    }
    &_content{
        display: flex;
        justify-content: space-between;
        max-height: 450px;
    }
    &_info{
        width: 39%;
        padding: 10px 20px;
        background: #6da0da;
        color: #fff;
        @media(max-width: 850px){
            width: 44%;
        }
        @media(max-width: 470px){
            width: 100%;
        }
    }

    &_info-item:not(:last-child){
        border-bottom: 1px solid #fff;
    }
    &_info-title{
        @media(max-width: 820px){
            margin: 10px;
    }
        @media(max-width: 550px){
            margin: 5px;
            font-size: 17px;
        }
        @media(max-width: 450px) {
            margin: 3px !important;
        }
    }
    &_info-text{
        font-family: $secondaryFont;
        @media(max-width: 680px){
            margin: 10px;
        }
        @media(max-width: 550px){
            margin: 5px;
            font-size: 14px;
        }

    }
    &_script{
        width: 59%;
        overflow: hidden;
        @media(max-width: 470px){
            display: none;
        }
    }
}

//FOOTER
.main-footer {
    font-family: $secondaryFont;
    font-weight: 400;

    &_inner {
        background: #3C4149;
    }

    &_list {
        display: flex;
        flex-wrap: wrap;
        color: #fff;
        @media(max-width: 600px) {
            flex-direction: column;
        }
    }
    &_item {
        width: 33.3%;
        padding: 30px 40px;
        @media(max-width: 1000px) {
            padding: 10px 15px;
        }

        @media(max-width: 600px) {
            width: 100%;
            text-align: center;
        }
    }
    &_item{
        @media(max-width: 800px) {
            width: 50%;
        }
    }
    &_item:last-child{
        @media(max-width: 800px) {
            width: 100%;
        }
        @media(max-width: 600px) {
            width: 100%;
            text-align: left;
        }
    }

    &_title {
        position: relative;
        font-size: 20px;
        font-weight: 500;

        &:before {
            background-color: #5cedff;
            content: '';
            width: 24px;
            height: 1px;
            text-align: inherit;
            bottom: -12px;
            position: absolute;
        }

        &:after {
            background-color: rgba(#fff, 0.8);
            content: '';
            width: calc(100% - 30px);
            height: 1px;
            bottom: -12px;
            position: absolute;
            right: 0;
        }
    }

    &_legality-content {
        line-height: 1.4;
        font-weight: 400;
    }

    &_nav {
        list-style: none;
        padding: 0;
    }

    &_nav-item {
        font-weight: 400;
        padding: 10px 0px;
    }

    &_nav-item:not(:last-child) {
        border-bottom: 1px solid rgba(#fff, 0.5);
    }

    &_contacts {
        display: flex;
        font-weight: 400;
    }
    & .icon{
        align-self: center;
        margin-right: 20px;
        width: 20px;
        height: 20px;
        fill: #6da0da;
    }

    &_line {
        font-size: 12px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        background: #282d35;
    }

    &_line-wrap {
        display: flex;
        justify-content: space-between;
        @media(max-width: 650px) {
            flex-direction: column;
            text-align: center;
        }
    }

    &_copyright {
        @media(max-width: 650px) {
            margin: 20px;
        }
    }

    &_web-development {
        color: #ccc;

        &:hover {
            border-bottom: 1px solid #fff;
        }
        & span{
            color: #fff;
        }
    }
    &_policy{
        display: inline-block;
        margin-top: 20px;
        border-bottom: 1px solid transparent;
        &:hover{
            border-bottom: 1px solid #fff;
        }
    }
}


#specialButton{
    display: inline-block;
    position: relative;
    margin-top: 40px;
}
#specialButton img{
    display: inline-block;
}
#specialButton:after{
    position: absolute;
    content: 'Версия для слабовидяищих';
    content: 'Версия для слабовидяищих';
    left: 120%;

}


