.reviews{
    font-family: $secondaryFont;
    &_main{
        background: #f3f3f3;
    }
    &_main-wrapper{
        display: flex;
        background: url("../img/bg/bg-pattern.png");
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 40px;
    }
    &_content{
        font-family: $secondaryFont;
        width: 75%;
        padding-right: 40px;
    }
    &_item{
        padding: 30px 60px 40px;
        background: #deebff;
        margin-bottom: 40px;
        border: 1px solid #ccc;
        border-radius: 10px;
        @media (max-width: 600px){
            padding: 15px 30px 30px;
        }
    }
    &_title{
        position: relative;
        margin-left: 50px;
        font-size: 18px;
    }
    &_text{
        font-style: italic;
        &:before{
            position: absolute;
            background: url("../img/quotes.png") no-repeat;
            content: '';
            top: 0;
            left: -50px;
            width: 30px;
            height: 30px;
            z-index: 100;
        }
        @media (max-width: 600px){
            font-size: 14px;
        }
    }
    &_info{
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 18px;
        @media (max-width: 600px){
            font-size: 16px;
        }
    }
    &_form-heading{
        font-size: 26px;
        text-align: center;
        color: #0069af;
    }
    &_form{
        max-width: 500px;
        margin: 0 auto 40px;
    }
    &_form input, &_form textarea{
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 7px 20px;
        margin-bottom: 20px;
        border: 1px solid #000;
    }
    &_form label{
        display: inline-block;
        margin-bottom: 5px;
    }
    &_btn-wrapper{
        text-align: center;
    }
    &_btn{
        display: inline-block;
        background: rgba(#66aae4, 0.8);
        padding: 10px 15px;
        border-radius: 5px;
        border: none;
        margin: 0 auto;
        transition: all .3s ease;
        cursor: pointer;
        &:hover{
        background: rgba(#66aae4, 1);
        }
    }
    &_status-wrapper{
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }
    &_status{
        display: inline-block;
        text-align: center;
        margin: 0 auto;
        font-family: $secondaryFont;
        font-size: 20px;
        background: $success;
        padding: 10px 20px;
        border-radius: 10px;
    }
    &_date{
        font-size: 14px;
    }
    &_name{
        font-weight: 500;
        font-size: 18px;
    }
}
