.btn-top {
  position: fixed;
  display: block;
  right: 40px;
  bottom: 40px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  padding: 10px;
  background-color: rgba(#0069af, 0.8);
  color: #fff;
  cursor: pointer;
  border: none;
  opacity: 0;
  z-index: -1000;
  outline: none;
    transition: all .5s ease;
  &:hover {
    background-color: rgba(#0069af, 1);
    transition: all .3s ease;
  }
}

.btn-top .icon{
    width: 30px;
    height: 30px;
}

.btn-show{
    opacity: 1;
    z-index: 1000;
}




