.p-services{
    font-family: $secondaryFont;
    &_list{

    }
    &_list:not(:last-child){
        border-bottom: 2px dashed #6DA0DA;
    }
    &_list:not(:first-child){
        margin-top: 40px;
        @media (max-width: 600px){
            margin-top: 20px;
        }
    }
    &_item{
        display: flex;
        padding-bottom: 40px;
        @media (max-width: 600px){
            flex-direction: column;
            width: 100%;
            align-items: center;
            text-align: center;
            padding-bottom: 20px;
        }
    }
    &_img{
        min-width: 250px;
    }
    &_content{
        width: 70%;
        padding-left: 20px;
    }
    &_title{
        display: inline-block;
        font-size: 25px;
        color: rgba(#0069af, 1);
        border: 1px solid transparent;
        transition: all .3s ease;
        padding-bottom: 2px;
        &:hover{
            color: rgba(#0069af, 0.6);
        }
        @media (max-width: 830px){
            font-size: 20px;
        }
        @media (max-width: 600px){
            margin-top: 10px;
        }
    }
    &_text{
        font-size: 14px;
        @media (max-width: 830px){
            font-size: 13px;
        }
    }
}
