// Fonts
$primaryFont             : 'Open Sans', Helvetica, sans-serif;;
$secondaryFont           : 'Roboto', Arial, sans-serif;
$advancedFont            : 'Lobster' Arial, sans-serif;

// Main colors
$default                 : #ffffff;
$primary                 : #444ce0;
$secondary               : #02557D;
$success                 : #26de81;
$danger                  : #fc5c65;
$warning                 : #fed330;
$light                   : #999999;
$dark                    : #070046;

// Other colors
$blue                    : #007bff;
$indigo                  : #6610f2;
$purple                  : #6f42c1;
$pink                    : #e83e8c;
$red                     : #dc3545;
$orange                  : #fd7e14;
$yellow                  : #ffc107;
$green                   : #28a745;
$teal                    : #20c997;
$cyan                    : #17a2b8;

// Neutral Colors
$neutral-primary         : #303133;
$neutral-secondary       : #909399;
$neutral-regular         : #606266;
$neutral-placeholder     : #C0C4CC;

// Border Colors
$border-base             : #DCDFE6;
$border-light            : #E4E7ED;
$border-lighter          : #EBEEF5;
$border-lightex          : #F2F6FC;



